



























































import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import STextarea from '@/common/components/STextarea.vue';
import TableInput from '@/tasks/components/inputs/TableInput.vue';
import STextareaShort from '@/common/components/STextareaShort.vue';

export default defineComponent({
  name: 'UCIrvineChem1LDELN3OIObservations3',
  components: {TableInput, STextarea, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        dataAnalysis1Calculations: null,
        characterizationTest: null,
        tableRows: [
          {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
          },
          {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
          },
        ],
      },
      columns: [
        {
          label: 'Column 1',
          component: STextareaShort,
          prop: 'a',
          width: '20%',
        },
        {
          label: 'Column 2',
          component: STextareaShort,
          prop: 'b',
          width: '20%',
        },
        {
          label: 'Column 3',
          component: STextareaShort,
          prop: 'c',
          width: '20%',
        },
        {
          label: 'Column 4',
          component: STextareaShort,
          prop: 'd',
          width: '20%',
        },
        {
          label: 'Column 5',
          component: STextareaShort,
          prop: 'e',
          width: '20%',
        },
      ],
      attachments: [] as File[],
      questions: [
        {
          text: 'Any written calculations, data values, and explanations may be included below.',
          inputName: 'dataAnalysis1Calculations',
        },
      ],
    };
  },
  computed: {
    attachments(): File[] {
      return [...this.attachments];
    },
  },
});
